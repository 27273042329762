import $ from 'jquery'

var startPos = {}
var endPos = {}
var isScrolling = 0;

window.slider = {
  //判断设备是否支持touch事件
  touch: ('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch,
  slider: document.getElementById('slider'),
  //事件
  events: {
    slider: window.slider,     //this为slider对象
    leftTouch: document.getElementById('icon-left'),
    rightTouch: document.getElementById('icon-right'),
    points: document.getElementsByClassName('point'),
    handleEvent: function (event) {
      var self = this;     //this指events对象
      if (event.type == 'touchstart') {
        self.start(event);
      } else if (event.type == 'touchmove') {
        self.move(event);
      } else if (event.type == 'touchend') {
        self.end(event);
      }
    },
    //滑动开始
    start: function (event) {
      var touch = event.targetTouches[0]   //touches数组对象获得屏幕上所有的touch，取第一个touch
      startPos = {x: touch.pageX, y: touch.pageY, time: +new Date};    //取第一个touch的坐标值
      isScrolling = 0;   //这个参数判断是垂直滚动还是水平滚动
    },
    //移动
    move: function (event) {
      //当屏幕有多个touch或者页面被缩放过，就不执行move操作
      if (event.targetTouches.length > 1 || event.scale && event.scale !== 1) return;
      var touch = event.targetTouches[0];
      endPos = {x: touch.pageX - startPos.x, y: touch.pageY - startPos.y};
      isScrolling = Math.abs(endPos.x) < Math.abs(endPos.y) ? 1 : 0;    //isScrolling为1时，表示纵向滑动，0为横向滑动
      if (isScrolling === 0) {
        event.preventDefault();      //阻止触摸事件的默认行为，即阻止滚屏
      }
    },
    //滑动释放
    end: function (event) {
      var duration = +new Date - startPos.time;    //滑动的持续时间
      if (isScrolling === 0) {    //当为水平滚动时
        if (Number(duration) > 10) {
          //判断是左移还是右移，当偏移量大于10时执行
          if (endPos.x > 10) {
            this.rightTouch.click()
          } else if (endPos.x < -10) {
            this.leftTouch.click()
          }
        }
      }
    }
  },

  //初始化
  init: function () {
    var self = this;     //this指slider对象
    if (self.touch) {
      self.slider.addEventListener('touchstart', self.events, false);
      this.slider.addEventListener('touchmove', self.events, false);
      this.slider.addEventListener('touchend', self.events, false);
    }
  }
};

$(document).ready(function () {
  var firstTime = true
  var publicityY = $(".publicity") ? $(".publicity").offset().top : 0

  $("header").addClass("transparent")
  $.fn.countTo = function (options) {
    options = options || {};

    return $(this).each(function () {
      // set options for current element
      var settings = $.extend({}, $.fn.countTo.defaults, {
        from: $(this).data('from'),
        to: $(this).data('to'),
        speed: $(this).data('speed'),
        refreshInterval: $(this).data('refresh-interval'),
        decimals: $(this).data('decimals')
      }, options);

      // how many times to update the value, and how much to increment the value on each update
      var loops = Math.ceil(settings.speed / settings.refreshInterval),
        increment = (settings.to - settings.from) / loops;

      // references & variables that will change with each update
      var self = this,
        $self = $(this),
        loopCount = 0,
        value = settings.from,
        data = $self.data('countTo') || {};

      $self.data('countTo', data);

      // if an existing interval can be found, clear it first
      if (data.interval) {
        clearInterval(data.interval);
      }
      data.interval = setInterval(updateTimer, settings.refreshInterval);

      // initialize the element with the starting value
      render(value);

      function updateTimer() {
        value += increment;
        loopCount++;

        render(value);

        if (typeof(settings.onUpdate) == 'function') {
          settings.onUpdate.call(self, value);
        }

        if (loopCount >= loops) {
          // remove the interval
          $self.removeData('countTo');
          clearInterval(data.interval);
          value = settings.to;

          if (typeof(settings.onComplete) == 'function') {
            settings.onComplete.call(self, value);
          }
        }
      }

      function render(value) {
        var formattedValue = settings.formatter.call(self, value, settings);
        $self.html(formattedValue);
      }
    });
  };

  $.fn.countTo.defaults = {
    from: 0,               // the number the element should start at
    to: 0,                 // the number the element should end at
    speed: 500,           // how long it should take to count between the target numbers
    refreshInterval: 100,  // how often the element should be updated
    decimals: 0,           // the number of decimal places to show
    formatter: formatter,  // handler for formatting the value before rendering
    onUpdate: null,        // callback method for every time the element is updated
    onComplete: null       // callback method for when the element finishes updating
  };

  function formatter(value, settings) {
    return value.toFixed(settings.decimals);
  }

  // custom formatting example
  $('#count-number').data('countToOptions', {
    formatter: function (value, options) {
      return value.toFixed(options.decimals).replace(/\B(?=(?:\d{3})+(?!\d))/g, ',');
    }
  });

  // start all the timers
  function count(options) {
    var $this = $(this);
    options = $.extend({}, options || {}, $this.data('countToOptions') || {});
    $this.countTo(options);
  }

  $(window).scroll(function () {
    if ($(window).scrollTop() > 0) {
      $("header").removeClass("transparent")
      $(".navbar-nav li a").css("font-weight", "400")
    } else {
      $("header").addClass("transparent")
      $(".navbar-nav li a").css("font-weight", "500")
    }

    if ($(window).scrollTop() > (publicityY - 200) && firstTime) {
      $('.timer').each(count)
      firstTime = false;
    }
  });

  $(window).width() >= 768 ? $("header").removeClass("mobile-header") :
    $("header").addClass("mobile-header")

  $(window).resize(function () {
    $(window).width() >= 768 ? $("header").removeClass("mobile-header") :
      $("header").addClass("mobile-header")
  })

  $(".app-blj").show();
  var timer = setInterval(function () {
    var next = $(".product-list").children(".active").next()
    $(".product-list").children(".active").removeClass("active")
    if (next.hasClass("item"))
      $(next).addClass("active")
    else
      $(".product-list").children(":first").addClass("active")
    var app = $(".product-list").children(".active").data('app')
    $("." + app).siblings().hide()
    $("." + app).fadeIn(1000)
  }, 3000)

  $(".product-list li").click(function () {
    $(this).addClass("active")
    $(this).siblings().removeClass("active")
    var app = $(this).data('app')
    $("." + app).siblings().hide()
    $("." + app).fadeIn(1000)
    clearInterval(timer)
  })

  $(".honor .right").click(function () {
    var now = $(".honor .items-wrap").children(".active")
    var next = $(".honor .items-wrap").children(".active").next()
    now.find("ul").animate({
      "margin-left": "100px",
      "opacity": "0",
    }, 700, function () {
      now.find("ul").css("margin-left", 0)
      if (!next.hasClass("item")) {
        next = $(".honor .items-wrap").children(":first")
      }
      next.find("ul").css("margin-right", "100px")
      next.find("ul").css("opacity", "0")
      $(".honor .items-wrap").children(".active").removeClass("active")
      next.addClass("active")
      next.find("ul").animate({
        "margin-right": "0",
        "opacity": "1",
      }, 700)
    })
  })

  $(".honor .left").click(function () {
    var now = $(".honor .items-wrap").children(".active")
    var prev = $(".honor .items-wrap").children(".active").prev()
    now.find("ul").animate({
      "margin-right": "100px",
      "opacity": "0"
    }, 700, function () {
      now.find("ul").css("margin-right", 0)
      if (!prev.hasClass("item")) {
        prev = $(".honor .items-wrap").children(":last")
      }
      prev.find("ul").css("margin-left", "100px")
      prev.find("ul").css("opacity", "0")
      $(".honor .items-wrap").children(".active").removeClass("active")
      prev.addClass("active")
      prev.find("ul").animate({
        "margin-left": "0",
        "opacity": "1",
      }, 700)
    })
  })

  function getHotspot() {
    var listLeft = '<ul>', listRight = '<ul>';
    var project_env = (window.location.href.indexOf('http://www') > -1
    || window.location.href.indexOf('https://www') > -1) ? 'https://' : 'https://qa-'

    $.get(project_env + 'blog.xingshulin.com/xingshulin', function (data) {
      for (var i = 0; i < 6; i++) {
        if (i < 3) listLeft += '<li><a class="clampOne" href="' + project_env + 'www.xingshulin.com/blog/article/' +
          data.posts[i].id + '">' + data.posts[i].createTime.substr(5, 5) + " | " + data.posts[i].title + '</a></li>'
        else if (i < 6) listRight += '<li><a class="clampOne" href="' + project_env + 'www.xingshulin.com/blog/article/' +
          data.posts[i].id + '">' + data.posts[i].createTime.substr(5, 5) + " | " + data.posts[i].title + '</a></li>'
      }
      $('.hotLeft').append(listLeft);
      $('.hotRight').append(listRight);
    })
  }

  $(".xl-expert .left").click(function () {
    $(".xl-expert .expert-main").animate({
      width: "100px",
      left: "40px",
      top: "40px",
    }, "fast")

    $(".xl-expert .expert-left").hide()
    $(".xl-expert .expert-left").removeClass("expert-left")
    $(".xl-expert .expert-main").addClass("expert-left")
    $(".xl-expert .expert-main").removeClass("expert-main")

    $(".xl-expert .expert-right").animate({
      width: "200px",
      left: "250px",
      top: 0
    }, "fast")

    $(".xl-expert .expert-right").addClass("expert-main")
    $(".xl-expert .expert-right").removeClass("expert-right")

    if ($(".xl-expert .expert-main").next().is("img")) {
      $(".xl-expert .expert-main").next().animate({
        width: "100px",
        left: "560px",
        top: '40px'
      }, "fast")
      $(".xl-expert .expert-main").next().addClass("expert-right")
    } else {
      $(".xl-expert .expert-img img:first").animate({
        width: "100px",
        left: "560px",
        top: "40px"
      }, "fast")
      $(".xl-expert .expert-img img:first").addClass("expert-right")
    }

    var next = $(".xl-expert .expert-list .active").next()
    if (!next.hasClass("expert-info")) {
      next = $(".xl-expert .expert-list").children(":first")
    }
    $(".xl-expert .expert-list .active").hide();
    $(".xl-expert .expert-list .active").removeClass("active");
    next.addClass("active");
    next.fadeIn()

    var nextPoint = $(".expert-point .point-active").next()
    if (!nextPoint.hasClass("point")) {
      nextPoint = $(".expert-point .point:first")
    }
    $(".expert-point .point").removeClass("point-active")
    nextPoint.addClass("point-active")
  })

  $(".xl-expert .right").click(function () {
    $(".xl-expert .expert-main").animate({
      width: "100px",
      left: "560px",
      top: "40px",
    }, "fast")

    $(".xl-expert .expert-right").hide()
    $(".xl-expert .expert-right").removeClass("expert-right")
    $(".xl-expert .expert-main").addClass("expert-right")
    $(".xl-expert .expert-main").removeClass("expert-main")

    $(".xl-expert .expert-left").animate({
      width: "200px",
      left: "250px",
      top: 0
    }, "fast")

    $(".xl-expert .expert-left").addClass("expert-main")
    $(".xl-expert .expert-left").removeClass("expert-left")

    if ($(".xl-expert .expert-main").prev().is("img")) {
      $(".xl-expert .expert-main").prev().animate({
        width: "100px",
        left: "40px",
        top: "40px"
      }, "fast")
      $(".xl-expert .expert-main").prev().addClass("expert-left")
    } else {
      $(".xl-expert .expert-img img:last").animate({
        width: "100px",
        left: "40px",
        top: "40px"
      }, "fast")
      $(".xl-expert .expert-img img:last").addClass("expert-left")
    }

    var prev = $(".xl-expert .expert-list .active").prev()
    if (!prev.hasClass("expert-info")) {
      prev = $(".xl-expert .expert-list").children(":last")
    }
    $(".xl-expert .expert-list .active").hide();
    $(".xl-expert .expert-list .active").removeClass("active");
    prev.addClass("active");
    prev.fadeIn()

    var prevPoint = $(".expert-point .point-active").prev()
    if (!prevPoint.hasClass("point")) {
      prevPoint = $(".expert-point .point:last")
    }
    $(".expert-point .point").removeClass("point-active")
    prevPoint.addClass("point-active")
  })

  getHotspot(); //获取最新动态

  $("#play_movie .close").click(function () {
    $("#play_movie").hide()
    $("#play_movie video").trigger("pause")
  })

  $(".expert-list .play-icon").click(function () {
    $("#play_movie").show()
    $("#play_movie video").attr("src", $(this).data("video"))
  })

  slider.init();
});

(function () {
  var referrer = getRefInf()
  sa.track('page_view', {
    page: '杏树林首页',
    url: location.href,
    source: document.referrer,
    server: 'web',
    web_name: referrer.searchengine,
    keyword: referrer.keyword,
    content: getHttpParams('from')
  });
})()
